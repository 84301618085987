<script lang="tsx">
import type { SlotsType } from 'vue'
import {
    type BaseUiElementGroupProvide,
    type BaseUiElementPositionInGroup,
    SymbolBaseUiElementGroup
} from '@core/app/composables/components'

export type BaseUiElementGroupProps<T> = {}

type BaseUiElementGroupSlots<T> = {
    default: {}
}

type ComponentOptions = {}

export function defineComponentBaseUiElementGroup<T>(options?: ComponentOverrideOptions<ComponentOptions, BaseUiElementGroupProps<T>, BaseUiElementGroupSlots<T>>) {
    return defineComponent(
        (props: BaseUiElementGroupProps<T>, ctx) => {
            function renderDefaultSlot(slotData: BaseUiElementGroupSlots<T>['default']) {
                const slotFunction = options?.slots?.default ?? ctx.slots.default

                const children = slotFunction?.(slotData)
                const normalizedChildren = Array.isArray(children) ? children : children ? [children] : []

                const hasMultipleChildren = normalizedChildren.length > 1
                if (!hasMultipleChildren) return normalizedChildren

                return normalizedChildren.map((child, index) =>
                    <BaseUiElementGroupProvider
                        position={index === 0 ? 'leading' : index === normalizedChildren.length - 1 ? 'trailing' : 'center'}
                    >
                        {child}
                    </BaseUiElementGroupProvider>
                )
            }

            return () => (
                <div class="sim-el-group">
                    {renderDefaultSlot({})}
                </div>
            )
        },
        {
            props: {},
            slots: Object as SlotsType<BaseUiElementGroupSlots<T>>,
            emits: {},
        }
    )
}

interface BaseUiElementGroupProviderProps {
    position: BaseUiElementPositionInGroup
}

const BaseUiElementGroupProvider = defineComponent(
    (props: BaseUiElementGroupProviderProps, ctx) => {

        provide<BaseUiElementGroupProvide>(SymbolBaseUiElementGroup, {
            position: () => props.position,
        })

        return () => ctx.slots.default?.({})
    },
    // eslint-disable-next-line vue/one-component-per-file
    {
        props: {
            position: {
                type: String as PropType<BaseUiElementGroupProviderProps['position']>,
                required: true,
            },
        },
        slots: {} as SlotsType<{ default: {} }>,
        emits: {},
    }
)

export default defineComponentBaseUiElementGroup()

</script>

<style lang="scss" scoped>
@use "@core-scss/components/BaseUiElementGroup.scss" as *;

</style>
